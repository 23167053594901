const mineRouter = {
  path: '/',
  name: 'Sidebar',
  redirect: '/mine/',
  component: () => import('@/components/sidebar'),
  children: [
    {
      path: 'mine',
      name: 'Index',
      meta: {
        title: '个人中心',
        type: 'login'
      },
      component: () => import('@/views/mine/index.vue')
    },
    {
      path: 'buylesson',
      name: 'LessonBuy',
      meta: {
        title: '已购买课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_lesson/buyLesson.vue')
    },
    {
      path: 'waitorder',
      name: 'Waitorder',
      meta: {
        title: '未付款课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_lesson/waitOrder.vue')
    },
    {
      path: 'lessoncollect',
      name: 'LessonCollect',
      meta: {
        title: '收藏的课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_lesson/lessonCollect.vue')
    },
    {
      path: 'expirelesson',
      name: 'expirelesson',
      meta: {
        title: '过期的课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_lesson/expirelesson.vue')
    },
    {
      path: 'freelesson',
      name: 'FreeLesson',
      meta: {
        title: '免费的课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_lesson/freeLesson.vue')
    },
    {
      path: 'coupon',
      name: 'Coupon',
      meta: {
        title: '未使用优惠券',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_coupon/coupon.vue')
    },
    {
      path: 'usedcoupon',
      name: 'UsedCoupon',
      meta: {
        title: '已使用优惠券',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_coupon/usedCoupon.vue')
    },
    {
      path: 'expired',
      name: 'Expired',
      meta: {
        title: '已过期优惠券',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_coupon/expireCoupon.vue')
    },
    {
      path: 'ExchangeCode',
      name: 'ExchangeCode',
      meta: {
        title: '未使用兑换码',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_ExchangeCode/ExchangeCode.vue')
    },
    {
      path: 'usedExchangeCode',
      name: 'usedExchangeCode',
      meta: {
        title: '已使用兑换码',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_ExchangeCode/usedExchangeCode.vue')
    },
    {
      path: 'expiredExchangeCode',
      name: 'expiredExchangeCode',
      meta: {
        title: '已过期兑换码',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_ExchangeCode/expiredExchangeCode.vue')
    },
    {
      path: 'collectData',
      name: 'CollectData',
      meta: {
        title: '收藏的资料',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_collect/collectData.vue')
    },
    {
      path: 'collectlesson',
      name: 'Collectlesson',
      meta: {
        title: '收藏的课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_collect/collectLesson.vue')
    },
    {
      path: 'collectthread',
      name: 'Collectthread',
      meta: {
        title: '收藏的帖子',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_collect/collectThread.vue')
    },
    {
      path: 'collecttopic',
      name: 'Collecttopic',
      meta: {
        title: '收藏的专题',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_collect/collectTopic.vue')
    },
    {
      path: 'myreview',
      name: 'Myreview',
      meta: {
        title: '评测记录',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_browser/myreview.vue')
    },
    {
      path: 'browserthread',
      name: 'BrowserThread',
      meta: {
        title: '浏览帖子',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_browser/browserThread.vue')
    },
    {
      path: 'browselesson',
      name: 'Browselesson',
      meta: {
        title: '浏览课程',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_browser/browselesson.vue')
    },
    {
      path: 'registinfo',
      name: 'RegistInfo',
      meta: {
        title: '注册信息',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_info/registInfo.vue')
    },
    {
      path: 'updatePwd',
      name: 'UpdatePwd',
      meta: {
        title: '修改密码',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_info/updatePwd.vue')
    },
    {
      path: 'address',
      name: 'Address',
      meta: {
        title: '地址管理',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_info/address.vue')
    },
    {
      path: 'express',
      name: 'Express',
      meta: {
        title: '我的快递',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_express/express.vue')
    },
    {
      path: 'taskcenter',
      name: 'Taskcenter',
      meta: {
        title: '任务中心',
        type: 'login'
      },
      component: () => import('@/views/mine/taskcenter/taskcenter.vue')
    },
    {
      path: 'tracking',
      name: 'Tracking',
      meta: {
        title: '物流详情',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_express/expressDetail.vue')
    },
    {
      path: 'upgrade',
      name: 'Upgrade',
      meta: {
        title: '会员升级记录',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_account/upgrade.vue')
    },
    {
      path: 'credit',
      name: 'Credit',
      meta: {
        title: '荣誉分记录',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_account/credit.vue')
    },
    {
      path: 'studuyCredit',
      name: 'StuduyCredit',
      meta: {
        title: '学分记录',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_account/studyCoin.vue')
    },
    {
      path: 'coin',
      name: 'Coin',
      meta: {
        title: '筑龙币记录',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_account/coin.vue')
    },
    {
      path: 'ExchangeRecord',
      name: 'ExchangeRecord',
      meta: {
        title: '兑换记录',
        type: 'login'
      },
      component: () => import('@/views/mine/ExchangeRecord/ExchangeRecord.vue')
    },
    {
      path: 'Invoice',
      name: 'Invoice',
      meta: {
        title: '开发票',
        type: 'login'
      },
      component: () => import('@/views/mine/mine_account/invoice.vue')
    },
    {
      path: 'user',
      name: 'User',
      meta: {
        title: '我关注的用户',
        type: 'login'
      },
      component: () => import('@/views/mine/main_thread/user.vue')
    },
    {
      path: 'group',
      name: 'Group',
      meta: {
        title: '我的小组',
        type: 'login'
      },
      component: () => import('@/views/mine/main_thread/group.vue')
    },
    {
      path: 'post',
      name: 'Post',
      meta: {
        title: '我的发帖',
        type: 'login'
      },
      component: () => import('@/views/mine/main_thread/post.vue')
    },
    {
      path: 'reply',
      name: 'Reply',
      meta: {
        title: '我的回复',
        type: 'login'
      },
      component: () => import('@/views/mine/main_thread/reply.vue')
    },
    {
      path: 'collectPost',
      name: 'CollectPost',
      meta: {
        title: '收藏的帖子',
        type: 'login'
      },
      component: () => import('@/views/mine/main_thread/collectPost.vue')
    },
    {
      path: 'download',
      name: 'Download',
      meta: {
        title: '我的下载',
        type: 'login'
      },
      component: () => import('@/views/mine/main_material/download.vue')
    },
    {
      path: 'upload',
      name: 'Upload',
      meta: {
        title: '我的上传',
        type: 'login'
      },
      component: () => import('@/views/mine/main_material/upload.vue')
    },
    {
      path: 'collectData1',
      name: 'CollectData1',
      meta: {
        title: '收藏的资料',
        type: 'login'
      },
      component: () => import('@/views/mine/main_material/collectData1.vue')
    },
    {
      path: 'evaluating',
      name: 'Evaluating',
      meta: {
        title: '我的评测',
        type: 'login'
      },
      component: () => import('@/views/mine/evaluating/index3.vue')
    },
    // 满意度调查
    {
      path: '/satisfaction',
      name: 'Satisfaction',
      meta: {
        title: '满意度调查',
        type: 'login'
      },
      component: () => import('@/views/mine/satisfaction/index')
    }
    // ,
    // {
    //   path: 'myCertificate',
    //   name: 'MyCertificate',
    //   meta: {
    //     title: '我的证书',
    //     type: 'login'
    //   },
    //   component: () => import('@/views/mine/myCertificate/index.vue')
    // }
  ]
}
export default mineRouter
