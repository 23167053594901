const certificateRouter = {
  path: '/certificate',
  name: 'certificate',
  // redirect: '/archive/',
  component: () => import('@/views/certificate/index.vue'),
  children: [
    {
      path: '/',
      name: 'certificate1',
      meta: {
        title: '证书查询'
        // type: 'login'
      },
      component: () => import('@/views/certificate/index.vue')
    },
    {
      path: 'archiveInfo',
      name: 'ArchiveInfo',
      meta: {
        title: '个人主页',
        type: 'login'
      },
      component: () => import('@/views/homepage/archive.vue')
    }
  ]
}
export default certificateRouter
