import Vue from 'vue'
import VueRouter from 'vue-router'
import mineRouter from './modules/mine'
import certificateRouter from './modules/certificate'
import archiveRouter from './modules/archive'
import homeRouter from './modules/indexpage'
import assessmentRouter from './modules/assessment'
import Cookies from 'js-cookie'
Vue.use(VueRouter)

const routes = [
  mineRouter,
  archiveRouter,
  homeRouter,
  certificateRouter,
  assessmentRouter,
  {
    // 此分支这个路由是微信扫码登录，如果需要改扫码登录，改这个页面
    path: '/login',
    name: 'Login',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/newLogin.vue')
  },
  {
    // 此分支这个路由是微信换绑，如果需要改扫换绑页面，改这个页面
    path: '/wxBindLogin',
    name: 'WxBindLogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/wxBindLogin.vue')
  },
  {
    // 学社App 隐私政策
    path: '/agreementOfAndroid',
    name: 'AgreementOfAndroid',
    meta: {
      title: '筑龙学社隐私政策'
    },
    component: () => import('@/views/mine/appAgreement/agreementOfAndroid.vue')
  },
  {
    // 注考帮App 隐私政策
    path: '/agreementOfAndroidZKB',
    name: 'agreementOfAndroidZKB',
    meta: {
      title: '注考帮隐私政策'
    },
    component: () => import('@/views/mine/appAgreement/agreementOfAndroidZKB.vue')
  },
  {
    // 这个路由是保留一份旧的登录页面，自己人使用
    path: '/oldLogin',
    name: 'OldLogin',
    meta: {
      title: '登录'
    },
    component: () => import('@/views/login/oldLogin.vue')
  },
  {
    path: '/bindPhone',
    name: 'BindPhone',
    meta: {
      title: '绑定手机'
    },
    component: () => import('@/views/login/bindPhone.vue')
  },
  {
    path: '/archive',
    name: 'archive',
    meta: {
      title: 'BBS · 个人主页',
      type: 'login' // 是否需要判断是否登录,这里是需要判断
    },
    component: () => import('@/views/homepage/archive.vue')
  },
  {
    path: '/activity',
    name: 'activity',
    meta: {
      title: '活动'
    },
    component: () => import('@/views/indexpage/activity.vue')
  },
  {
    path: '/agreement',
    name: 'Agreement',
    meta: {
      title: '筑龙网付费协议'
    },
    component: () => import('@/components/agreement')
  },
  // 用户协议
  {
    path: '/useragreement',
    name: 'useragreement',
    meta: {
      title: '用户协议'
      // type: 'login'
    },
    component: () => import('@/components/useragreement')
  },
  {
    path: '/redeem/get',
    name: 'getExchangeCode',
    meta: {
      title: '领取兑换码'
      // type: 'login'
    },
    component: () => import('@/views/mine/mine_ExchangeCode/getExchangeCode')
  },
  {
    path: '/getExchangeCodeSuccess',
    name: 'getExchangeCodeSuccess',
    meta: {
      title: '领取成功'
      // type: 'login'
    },
    component: () => import('@/views/mine/mine_ExchangeCode/getExchangeCodeSuccess')
  },
  {
    path: '/agreementSignature',
    name: 'AgreementSignature',
    meta: {
      title: '保障协议'
    },
    component: () => import('@/components/agreementSignature')
  },
  // 忘记密码
  {
    path: '/forgetPassword',
    name: 'ForgetPassword',
    meta: {
      title: '忘记密码'
    },
    component: () => import('../views/login/forgetPassword.vue')
  },
  {
    path: '/teacherRegistinfo',
    name: 'TeacherRegistinfo',
    meta: {
      title: '注册信息',
      type: 'login'
    },
    component: () => import('@/views/homepage/teacherRegistInfo.vue')
  },
  {
    path: '/standardSeach',
    name: 'standardSeach',
    meta: {
      title: 'AI建筑问答'
    },
    component: () => import('@/views/chatGPT/index.vue')
  },
  {
    path: '/standardSeachV2',
    name: 'standardSeachV2',
    meta: {
      title: 'AI建筑问答'
    },
    component: () => import('@/views/chatGPT/standardSeach.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.query.radar) {
    var h = document.createElement('script')
    h.src = 'https://zhulong-lib.oss-cn-beijing.aliyuncs.com/js/time_position_tools.js'
    var s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(h, s)
  }
  if (to.meta.title) {
    document.title = to.meta.title
  }
  const type = to.meta.type
  // 判断该路由是否需要登录权限
  if (type === 'login') {
    var uid = Cookies.get('uid')
    if (uid != null) {
      next()
    } else {
      if (to.path === '/login' || to.path === '/archive') {
        next()
      } else {
        next({
          path: '/login',
          query: { redirect: window.location.href }// 将目的路由地址存入login的query中
        })
      }
    }
  } else {
    next() // 确保一定要有next()被调用
  }
})
// 解决vue中的NavigationDuplicated {_name: "NavigationDuplicated", name: "NavigationDuplicated"}
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
