import Cookies from 'js-cookie'

// 用户信息
const state = {
  avatar: Cookies.get('avatar') ? Cookies.get('avatar') : '',
  uid: Cookies.get('uid') ? Cookies.get('uid') : '',
  username: Cookies.get('username') ? Cookies.get('username') : '',
  vip_level: Cookies.get('vip_level') ? Cookies.get('vip_level') : ''
}
const getters = {
  getAva() {
    return this.avatar
  }
}
const mutations = {
  storageAvatar: (state, info) => {
    state.avatar = info.avatar
    Cookies.set('avatar', info.avatar, { expires: 7 })
  },
  storageUid: (state, info) => {
    state.uid = info.uid
  },
  storageUsername: (state, info) => {
    state.username = info.username
    Cookies.set('username', info.username, { expires: 7 })
  },
  storageVipLevel: (state, info) => {
    state.vip_level = info.vip_level
    Cookies.set('vip_level', info.vip_level, { expires: 7 })
  }
}
const actions = {
  storageTheAvatar: (context, info) => {
    context.commit('storageAvatar', info)
  },
  storageTheUid: (context, info) => {
    context.commit('storageUid', info)
  },
  storageTheUsername: (context, info) => {
    context.commit('storageUsername', info)
  },
  storageTheVipLevel: (context, info) => {
    context.commit('storageVipLevel', info)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
