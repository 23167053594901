const archiveRouter = {
  path: '/archive',
  name: 'Archive',
  redirect: '/archive/',
  component: () => import('@/components/homepage/homepageTop.vue'),
  children: [
    {
      path: '/',
      name: 'archiveIndex',
      meta: {
        title: '我的主页',
        type: 'login'
      },
      component: () => import('@/views/homepage/subjects.vue')
    },
    {
      path: 'archiveInfo',
      name: 'ArchiveInfo',
      meta: {
        title: '我的主页',
        type: 'login'
      },
      component: () => import('@/views/homepage/archive.vue')
    },
    {
      path: 'archiveLesson',
      name: 'ArchiveLesson',
      meta: {
        title: '个人主页',
        type: 'login'
      },
      component: () => import('@/views/homepage/archiveLesson.vue')
    },
    {
      path: 'interestList',
      name: 'InterestList',
      meta: {
        title: '关注列表',
        type: 'login' // 是否需要判断是否登录,这里是需要判断
      },
      component: () => import('@/views/homepage/interestList.vue')
    },
    {
      path: 'fanList',
      name: 'FanList',
      meta: {
        title: '粉丝列表',
        type: 'login' // 是否需要判断是否登录,这里是需要判断
      },
      component: () => import('@/views/homepage/FanList.vue')
    },
  ]
}
export default archiveRouter
