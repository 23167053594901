const homeRouter = {
  path: '/gift',
  name: 'gift',
  meta: {
    title: '礼品兑换首页'
  },
  component: () => import('@/views/indexpage/index.vue'),
  children: [
    {
      path: '',
      name: 'indexpage',
      component: () => import('@/views/indexpage/indexpage.vue')
    },
    {
      path: 'giftinfo',
      name: 'Giftinfo',
      meta: {
        title: '礼品页'
      },
      component: () => import('@/views/indexpage/giftinfo.vue')
    },
    {
      path: 'more',
      name: 'More',
      meta: {
        title: '更多'
      },
      component: () => import('@/views/indexpage/more.vue')
    },
    {
      path: 'searchResult',
      name: 'searchResult',
      meta: {
        title: '搜索结果'
      },
      component: () => import('@/views/indexpage/search_result.vue')
    },
    {
      path: 'RedeemNow',
      name: 'RedeemNow',
      meta: {
        title: '礼品兑换'
      },
      component: () => import('@/views/indexpage/giftexchange.vue')
    },
    {
      path: 'paysuccess',
      name: 'paysuccess',
      meta: {
        title: '支付成功'
      },
      component: () => import('@/views/indexpage/paysuccess.vue')
    },
    {
      path: 'RedeemNow_jdyx',
      name: 'RedeemNow_jdyx',
      meta: {
        title: '礼品兑换-京东优选'
      },
      component: () => import('@/views/indexpage/jdyx_giftexchange.vue')
    },
    {
      path: 'giftinfo_jdyx',
      name: 'giftinfo_jdyx',
      meta: {
        title: '礼品页-京东优选'
      },
      component: () => import('@/views/indexpage/jdyx_giftinfo.vue')
    },
    {
      path: 'giftExchange',
      name: 'GiftExchange',
      meta: {
        title: '礼品页'
      },
      component: () => import('@/views/indexpage/zl_jd_giftinfo.vue')
    }
  ]
}
export default homeRouter
