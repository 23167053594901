const assessmentRouter = {
  path: '/assessment',
  name: 'Assessment',
  meta: {
    status: 0
  },
  component: () => import('@/views/assessment/index'),
  children: [
    {
      path: '/assessment/cultrue',
      name: 'Cultrue',
      component: () => import('@/views/assessment/cultrue'),
      meta: {
        status: 1,
        keepAlive: true
      }
    },
    {
      path: '/assessment/exhibition',
      name: 'Exhibition',
      component: () => import('@/views/assessment/exhibition'),
      meta: {
        status: 1,
        keepAlive: false
      }
    },
    {
      path: '/assessment/assuccess',
      name: 'Assuccess',
      component: () => import('@/views/assessment/submitsuccess'),
      meta: {
        status: 1,
        keepAlive: false
      }
    }
  ]

}
export default assessmentRouter

