import request from '@/utils/im_request'

// 获取页面 im 弹窗配置
export function getimConf(params) {
  return request({
    url: '/im/sale/getimConf',
    method: 'get',
    params
  })
}
