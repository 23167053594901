<template>
  <div id="app">
    <zlfollowPop></zlfollowPop>
    <zlheaderold v-show="isShow" project="ucenter" :head-zkb-index="zkb_header_index"></zlheaderold>
    <router-view />
    <!-- <foot v-show="isShow"></foot> -->
    <zlfooter v-show="isShow"></zlfooter>
    <!-- 兴趣点、加微弹窗 -->
    <!-- <activeModal v-if="!isZKb && isloguid"></activeModal> -->
    <!-- 消息弹窗 -->
    <imalert v-if="show_im" :im_gid="im_gid" :im_popupid="im_popupid"></imalert>
  </div>
</template>
<!-- <style lang="less">-->
<style lang="scss">
#app {
  position: relative;
  font-family: "微软雅黑", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
html,body{
    height:100%;
    width: 100%;
    background: #f2f2f2 ;
}
body{
  margin: 0;
}
*{
  margin: 0;
  padding: 0;
}
p{
  margin: 0;
}
a{
  text-decoration: none;
  color: #ccc ;
}
</style>
<script>
// import Header from '@/components/head.vue'
// import Footer from '@/components/footer.vue'
import { getPublic } from '@/api/public.js'
import { getimConf } from '@/api/im_api.js'
import Cookies from 'js-cookie'

export default {
  components: {
    // vhead: Header,
    // foot: Footer
  },
  data() {
    return {
      isShow: true,
      name: '',
      // 消息弹窗
      im_gid: '',
      im_popupid: '',
      show_im: false,
      // 注考帮头部高亮
      zkb_header_index: -1,
      // 是注考帮，去掉弹窗
      isZKb: false,
      isloguid: false
    }
  },
  watch: {
    $route: {
      handler(newVal) {
        console.log(newVal, 'newVal')
        // 路由变化就请求 请求配置im
        this.getImConfig(newVal.path)
        if (newVal.path === '/login' || newVal.path === '/bindPhone' || newVal.path === '/oldLogin' || newVal.path === '/wxBindLogin') {
          this.isShow = false
        }
        // 检测注考帮的路由，头部礼品 设置高亮
        if (window.location.href.indexOf('zk468') !== -1 || window.location.href.includes('zkbpro')) {
          this.checkZkbGiftRouter(newVal)
        }
      }
    }
  },
  created() {
    // 是否登录
    const uid = Cookies.get('uid')
    if (uid && uid != 0 && uid != '') {
      this.isloguid = true
    }
    // 是否是注考帮
    const origin = window.location.origin
    if (this.agency_id == 11 || origin.includes('zk468') || origin.includes('zkbpro')) {
      this.isZKb = true
    }
    const head = document.getElementsByTagName('head')
    const meta = document.createElement('meta')
    meta.name = 'referrer'
    // 根据实际情况修改referrer的值，可选值参考上文
    meta.content = 'no-referrer-when-downgrade'
    head[0].appendChild(meta)
    this.getPublicFn()
  },
  methods: {
    // 检测注考帮的路由，头部礼品 设置高亮
    checkZkbGiftRouter(val) {
      if (val.path.indexOf('/gift') !== -1) {
        this.zkb_header_index = 4
      }
    },

    getPublicFn() {
      const params = {
        type: 1
      }
      getPublic(params).then(res => {
        this.name = res.result.top.agency.short_name
        sessionStorage.setItem('name_zl', this.name)
      })
    },
    // 获取im配置信息
    getImConfig(toPath) {
      // agency_id 机构id
      // im_product_id  产品id
      // im_type 产品类型1课程 2普通产品 3链接
      // im_url ：链接地址
      // im_gid：接待组id
      // 默认false
      this.show_im = false
      const url = window.location.href
      var im_type = '3'
      var im_url = url
      var im_product_id = ''
      if (toPath.indexOf('/d/') !== -1) {
        // 课程
        const lesson_id = this.$route.query.lesson_id || this.$route.params.lesson_id.slice(0, -5)
        im_type = '1'
        im_product_id = lesson_id
        im_url = ''
      }
      const query = {
        agency_id: Cookies.get('agency_id') || '',
        im_type: im_type,
        im_product_id: im_product_id,
        im_url: im_url,
        im_gid: ''
      }
      console.log('IM 配置', query)
      getimConf(query).then(res => {
        this.im_gid = String(res.im_gid) || ''
        this.im_popupid = String(res.im_popupid) || ''
        this.show_im = res.show || 0
        console.log('展示IM', this.show_im)
      })
    }
  }
}
</script>
