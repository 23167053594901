import Vue from 'vue'
import 'ant-design-vue/dist/antd.css'
import './styles/index.less'
import './styles/mineInfo.less'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import VDistpicker from 'v-distpicker'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './styles/element-variables.scss'
import 'zhulong-common/dist/zl-common.css'
import zlCommon from 'zhulong-common'
Vue.use(zlCommon)
// 图表
// import echarts from 'echarts'
// Vue.prototype.$echarts = echarts
import { Message } from 'element-ui'
import VueCropper from 'vue-cropper'

import zlImalert from 'zl-imalert-common'
import 'zl-imalert-common/dist/zl-imalert-common.css'
Vue.use(zlImalert)

Vue.component('v-distpicker', VDistpicker)
Vue.use(ElementUI)

Vue.use(VueCropper)

Vue.config.productionTip = false
Vue.use(Antd)
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
export default Vue
